// Customizable Area Start
import React from "react";
import BlogPostsManagementController, { Item, Props } from "./BlogPostsManagementController";
import { backIcon, firstImage } from "./assets";
import { Box, Card, CardActionArea, CardContent, CardMedia, IconButton, InputBase, Paper, Typography, styled } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Header from "../../../components/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ExploreTypography = styled(Typography)({
  color: '#1C2110',
  fontFamily: 'Poppins',
  fontSize: 30,
  marginTop: 30,
  '@media(max-width: 960px)': {
    color: '#1C2110',
    fontSize: 25,
    fontFamily: 'Poppins',
    marginTop: 30,
  },
  '@media(max-width: 600px)': {
    color: '#1C2110',
    fontFamily: 'Poppins',
    fontSize: 16,
    marginTop: 30,
  },
});

const CardBox = styled(Box)({
  '@media(max-width: 960px)': {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '32px',
    maxWidth: '1012px',
    width: '100%',
    marginBottom: 50,
    justifyContent: 'center'
  },
  '@media(max-width: 600px)': {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '32px',
    maxWidth: '1012px',
    width: '100%',
    marginBottom: 50,
    justifyContent: 'center'
  },
})

const BlogTypography = styled(Typography)({
  color: '#78716C',
  fontWeight: 400,
  fontFamily: 'Poppins',
  fontSize: 20,
  marginLeft: '1%',
  borderBottom: '2px solid #78716C',
  width: 20,
  display: 'inline-block',
  paddingBottom: '10px',
  '@media(max-width: 960px)': {
    color: '#78716C',
    fontSize: 20,
    fontFamily: 'Poppins',
    fontWeight: 400,
    marginLeft: '2%',
    borderBottom: '2px solid #78716C',
    width: 20,
    display: 'inline-block',
    paddingBottom: '10px',
  },
  '@media(max-width: 600px)': {
    color: '#78716C',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Poppins',
    borderBottom: '2px solid #78716C',
    marginLeft: '2%',
    width: 20,
    display: 'inline-block',
    paddingBottom: '10px',
  },
})

const BlogTextLineBox = styled(Box)({
  position: 'absolute',
  bottom: 0,
  width: '6%',
  left: 10,
  borderBottom: '2px solid #78716C',
  zIndex: -1,
  '@media(max-width: 900px)': {
    position: 'absolute',
    bottom: 0,
    width: '7%',
    left: '18px',
    borderBottom: '2px solid #78716C',
    zIndex: -1,
  },
  '@media(max-width: 600px)': {
    position: 'absolute',
    bottom: 0,
    width: '11%',
    left: 10,
    borderBottom: '2px solid #78716C',
    zIndex: -1,
  }
});

const SearchPaper = styled(Paper)({
  padding: "2px 4px",
  alignItems: "center",
  display: "flex",
  width: 780,
  marginTop: 108,
  height: 44,
  borderRadius: "40px",
  border: "1px solid #8CA74B",
  '@media(max-width: 900px)': {
    padding: "2px 4px",
    display: "flex",
    width: 780,
    alignItems: "center",
    height: 44,
    marginTop: 108,
    borderRadius: "40px",
    border: "1px solid #8CA74B",
    marginLeft: '10px'
  },
  '@media(max-width: 600px)': {
    padding: "2px 4px",
    display: "flex",
    width: 780,
    alignItems: "center",
    height: 44,
    marginTop: 108,
    borderRadius: "40px",
    border: "1px solid #8CA74B",
    marginLeft: '10px'
  }
});

const AllCategoriesBox = styled(Box)({
  cursor: "pointer",
  background: "white",
  display: "flex",
  alignItems: "center",
  padding: 14,
  justifyContent: "space-between",
  borderRadius: 8,
  fontWeight: 500,
  border: `1px solid #8CA74B`,
  fontSize: 16,
  color: "#8CA74B",
  '@media(max-width: 900px)': {
    cursor: "pointer",
    display: "flex",
    background: "white",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 14,
    borderRadius: 8,
    fontWeight: 500,
    border: `1px solid #8CA74B`,
    fontSize: 16,
    color: "#8CA74B",
    marginRight:'10px'
  },
  '@media(max-width: 600px)': {
    cursor: "pointer",
    background: "white",
    display: "flex",
    alignItems: "center",
    padding: 14,
    justifyContent: "space-between",
    borderRadius: 8,
    fontWeight: 500,
    border: `1px solid #8CA74B`,
    fontSize: 16,
    color: "#8CA74B",
    marginRight:'10px'
  }
})


export default class BlogpostsManagement extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <Box>
        {
          this.state.authToken ? (
            <HeaderDropdown
              data-test-id="headerComponenttestid"
              navigation={undefined}
              id={""}
            />
          ) : (
            <Header onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={() => { }} />
          )
        }
        <Box sx={webStyle.container}>
          <Box sx={webStyle.header}>
            <img src={backIcon} style={webStyle.backIcon} />
            <SearchPaper
              component="form"
              onSubmit={this.handleSubmit}
            >
              <IconButton type="button" style={{ padding: "10px", color: "#8CA74B" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                data-test-id="inputBoxId"
                style={{ marginLeft: 1, flex: 1, color: "rgb(103, 147, 8)", fontSize: 18, fontWeight: 500, fontFamily: "Poppins" }}
                value={this.state.searchText}
                placeholder="Search"
                onChange={(e) => this.handleChange(e)}
                inputProps={{ className: "custom-input" }}

              />
              {this.state.searchText && (
                <IconButton data-test-id="clearBtn" onClick={this.handleClear} style={{ color: "#8CA74B", padding: "10px" }} aria-label="clear">
                  <ClearIcon />
                </IconButton>
              )}


            </SearchPaper>
            <div style={{ fontFamily: "sans-serif" }}>
              <div
                style={{
                  margin: "0 auto",
                  width: "188px",
                  zIndex: 10000,
                  height: 55,
                  marginTop: 92,
                  position: "relative",
                  marginLeft: 30,
                }}
              >
                <AllCategoriesBox
                  onClick={this.handleToggle.bind(this)}
                >
                  {this.state.selected}
                  {this.state.isActive ? (
                    <KeyboardArrowUpIcon
                      style={{
                        color: '#8CA74B',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{
                        color: '#8CA74B',
                        transition: 'transform 0.3s ease',
                      }}
                    />
                  )}
                </AllCategoriesBox>

                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
                    borderRadius: 8,
                    // background: 'white',
                    display: this.state.isActive ? "block" : "none"
                  }}
                >
                  {[
                    { name: "All categories", id: "all" },
                    ...this.state.categoriesData
                  ].map((option, index) => (
                    <div
                      key={index}
                      className="item"
                      style={{
                        padding: 10,
                        cursor: "pointer",
                        color: "#8CA74B",
                        backgroundColor:
                          this.state.selected === option.name ? "lightGray" : "white", // Highlight selected option
                        borderRadius: this.state.selected === option.name ? 4 : 0, // Optional: rounded corners for highlight
                      }}
                      onClick={this.handleOptionSelect.bind(this, option)}
                    >
                      {option.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>


          </Box>

          <Box position="relative" display="inline-block" mt={2}>
            <BlogTypography
            >
              BLOG
            </BlogTypography>
            <BlogTextLineBox
            />
          </Box>

          <Box sx={webStyle.CardMainContent}>
            <ExploreTypography>
              <span style={{ fontWeight: 700 }}>Explore & Inspire:</span> Journey Through the Blog
            </ExploreTypography>
            <CardBox style={webStyle.cardStyles}>

              {this.state.blogDetails.map((data: any) => {
                { console.log("response 170", JSON.stringify(this.state.blogDetails)) }
                return <Card style={{ width: '316px', height: '470px', borderRadius: 16 }} onClick={this.gotoDestinationScreen.bind(this, data)}>
                  <CardActionArea>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: "#E8FFB1",
                        width: 115,
                        height: 25,
                        marginLeft: 25,
                        borderRadius: 10,
                        marginTop: 30
                      }}
                    >
                      <Typography
                        style={{ color: "#557C29", textAlign: "center", alignSelf: "center", fontSize: 12 }}
                      >
                        {data.category_name}
                      </Typography>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <CardMedia
                        component="img"
                        style={{ borderRadius: 8, marginTop: 20, width: 270, height: 200 }}
                        image={data.attachment_urls[0]?.url}
                      />
                    </Box>
                    <CardContent>
                      <Typography
                        style={{
                          fontSize: 17,
                          position: "relative",
                          fontFamily: "Poppins",
                          fontWeight: 700,
                          marginLeft: 15,
                          maxWidth: "260px",
                          width: "100%",
                          height: 52
                        }}
                      >
                        <span
                          style={{
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            borderLeft: "7px solid #557C29",
                            left: -15,
                          }}
                        ></span>
                        {data.title}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 16,
                          fontFamily: "Poppins",
                          fontWeight: 400,
                          marginTop: 30,
                          marginLeft: 25,
                          width: 230
                        }}
                      >
                        {this.truncateText(data.content, 60)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              })}



            </CardBox>
          </Box>
        </Box>
        <Footer navigation={this.props.navigation} id={""} />
      </Box>
      // Customizable Area End
    );
  }
}

const webStyle = {
  backIcon: {
    width: 32,
    height: 32,
    marginTop: 92,
    position: 'absolute' as 'absolute',
    left: -120,
    cursor: "Pointer"
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1016px',
    margin: 'auto'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  blogText: {
    color: '#78716C',
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginLeft: '1%'
  },
  exploreInspire: {
    color: '#1C2110',
    fontFamily: 'Poppins',
    fontSize: 30,
    marginTop: 30
  },
  CardMainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardStyles: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '32px',
    maxWidth: '1012px',
    width: '100%',
    marginBottom: 50
  } as any
};



// Customizable Area End
