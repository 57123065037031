import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End


interface S {
  // Customizable Area Start
  selectedView: string,
  drawerOpen: boolean,
  showShareContent: boolean,
  isGroupsExpanded:boolean,
  isPhotoExpanded:boolean,
  isPeopleExpanded:boolean,
  isMemoriesExpanded:boolean,
  isFamilyTreeExpanded:boolean,
  isRecipesExpanded:boolean,
  page:number,
  totalPages:number,
  relativeModal: boolean;
  momentModal: boolean;
  charCount: number;
  dragActive: boolean;
  selectedFile: any,
  recipesModal: boolean;
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  alignment: 'left' | 'center' | 'right';
  isUppercase: boolean;
  tags: string[];
  inputValue: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SharingDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedView: 'myFiles',
      drawerOpen: false,
      showShareContent: false,
      isGroupsExpanded: false,
      isPhotoExpanded: false,
      isPeopleExpanded: false,
      isMemoriesExpanded: false,
      isFamilyTreeExpanded: false,
      isRecipesExpanded:false,
      page: 1,
      totalPages: 2,
      relativeModal: false,
      momentModal: false,
      charCount: 0,
      dragActive: false,
      selectedFile: null,
      recipesModal: false,
      isBold: false,
      isItalic: false,
      isUnderline: false,
      alignment: 'left',
      isUppercase: false,
      tags: [],
      inputValue: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  handleRelativeModalOpen = () => {
    this.setState({ relativeModal: true });
  };

  handleMomentModalOpen = () => {
    this.setState({ momentModal: true });
  };

  handleMomentModalClose = () => {
    this.setState({ momentModal: false });
  };

  handleRelativeModalClose = () => {
    this.setState({relativeModal: false})
  }
  handleInputChange = (event: any) => {
    this.setState({ charCount: event.target.value.length });
  };
  
  handleDrag = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: true });
};

  handleDragLeave = (e:any) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
};

handleDrop = (e:any) => {
  e.preventDefault();
  e.stopPropagation();
  this.setState({ dragActive: false });

  const files = e.dataTransfer.files;
  if (files && files[0]) {
      this.setState({ selectedFile: files[0] });
      console.log("File dropped:", files[0].name); 
  }
};

handleFileSelection = (e:any) => {
  const file = e.target.files[0];
  if (file) {
      this.setState({ selectedFile: file });
      console.log("Selected file:", file.name); 
  }
};

triggerFileInput = () => {
  document.getElementById('file-upload')?.click();
};

handleRecipesModalOpen = () => {
  this.setState({ recipesModal: true });
};

handleRecipesModalClose = () => {
  this.setState({ recipesModal: false });
};

toggleBold = () => {
  this.setState((prevState) => ({ isBold: !prevState.isBold }));
};

toggleItalic = () => {
  this.setState((prevState) => ({ isItalic: !prevState.isItalic }));
};

toggleUnderline = () => {
  this.setState((prevState) => ({ isUnderline: !prevState.isUnderline }));
};

setAlignment = (alignment: 'left' | 'center' | 'right') => {
  this.setState({ alignment });
};

toggleUppercase = () => {
  this.setState((prevState) => ({ isUppercase: !prevState.isUppercase }));
};

handleDelete = (tagToDelete: string) => {
  this.setState((prevState) => ({
    tags: prevState.tags.filter((tag) => tag !== tagToDelete),
  }));
};

handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter' && this.state.inputValue.trim()) {
    this.setState((prevState) => {
      const { tags, inputValue } = prevState;

      // Create a new tags array only if the input value is not already in the tags
      const newTags = !tags.includes(inputValue)
        ? [...tags, inputValue]
        : tags;

      return {
        tags: newTags,  // Ensure tags is always included in the return value
        inputValue: '',  // Reset the input value
      };
    });

    event.preventDefault(); // Prevents newline on Enter
  }
};

handleInputChangeTags = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ inputValue: event.target.value });
};

  // Customizable Area End
}    