import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import SharingDashboardController, {
    Props,
} from "./SharingDashboardController";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { trashIcon } from "./assets";
import SideDrawer from "../../../components/src/SideDrawer";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import MyFiles from "./MyFiles.web";
// Customizable Area End

export default class SharingDashboard extends SharingDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <SharingDashboardStyle>
                <SideDrawer initialSelectedItem="Sharing"/>
                <Box className="MainContent">
                    <Box className="Header">
                        <HeaderDropdown
                            data-test-id="headerComponent" navigation={this.props.navigation} id={""}
                        />
                    </Box>
                    {this.state.showShareContent ? (
                        <MyFiles navigation={undefined} id={""} />
                    ) : (
                        <Box className="centerDiv">
                            <img className="img" src={trashIcon} alt="Trash Icon" />
                            <Typography className="welcomeTitle">Welcome to your shared files</Typography>
                            <ul className="ulDiv">
                                <li className="liText">No items have been shared yet</li>
                                <li className="liText">You will have the authority to approve or disapprove shared files.</li>
                                <li className="liText">you will be able to change the permissions of each user.</li>
                            </ul>
                            <Button className="shareBtn" data-test-id="shareBtn" onClick={this.handleShareBtn}><AddCircleOutlineOutlinedIcon /><Typography className="shareBtnText">Share</Typography></Button>
                        </Box>
                    )}
                </Box>
            </SharingDashboardStyle>
        );
    }
}
// Customizable Area End

// Customizable Area Start
const SharingDashboardStyle = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    "& .MainContent": {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    "& .Content": {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginLeft: '20px'
    },
    "& .img": {
        height: '239px',
        width: '239px'
    },
    "& .centerDiv": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '50px',
        marginBottom: '50px'
    },
    "& .welcomeTitle": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "30px",
        lineHeight: '40px',
        fontWeight: 700,
        letterSpacing: '-0.5%',
        marginTop: '30px'
    },
    "& .ulDiv": {
        marginTop: '30px',
        display: 'flex',
        flexDirection: "column",
        alignItems: "center"
    },
    "& .liText": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "16px",
        lineHeight: '24px',
        fontWeight: 400,
    },
    "& .shareBtn": {
        backgroundColor: "#557C29",
        height: '56px',
        borderRadius: '8px',
        padding: '4px',
        width: '162px',
        marginTop: '40px',
        color: "#FFFFFF",
    },
    '& .shareBtnText': {
        marginLeft: '8px',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        color: "#FFFFFF",
        textTransform: "none",
    }
});
// Customizable Area End
