import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    Divider,
    IconButton,
    InputBase,
    Paper,
    Typography
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CreateGroupController, {
    Props,
} from "./CreateGroupController.web";
import { avatar, BackArrowBlack, dummyCreateImg, iIcon } from "./assets";
import StarIcon from '@material-ui/icons/Star';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from "@mui/icons-material/Search";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@mui/icons-material/Check';

export default class CreateGroup extends CreateGroupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            <Container>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box style={{ display: 'flex', alignItems: 'center', marginLeft: '-22px' }}>
                        <Button className="backButton" data-test-id="backButton">
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Create a Sharing Group</Typography>
                    </Box>
                    <Box style={{ display: 'flex', gap: '15px' }}>
                        <Box>
                            <Typography className="selectText">Select content</Typography>
                            <Box style={{ display: 'flex', justifyContent: 'end', gap: '5px' }}><Box className="bgDigitBox"><Typography className="selectedItemDigit">5</Typography></Box><Typography className="selectedText">Selected</Typography></Box>
                        </Box>
                        <Box style={{ display: 'flex', gap: '10px' }}>
                            <Button className="doneBtn" data-test-id="handleDoneBtn" onClick={this.handleDoneBtn}>Done</Button>
                            <Button className="cancelBtn">Cancel</Button>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ marginTop: '30px' }}>
                    <Typography className="filterTextTitle">Filter by</Typography>
                    <Box className="filterMainBoxs">
                        <Box className="filterSubBoxs">
                            <Box className="filterBoxDiv">
                                <StarIcon style={{ height: '16px', width: '16px' }} /> <Typography className="filterBoxTextTitle" style={{ marginLeft: '2px' }}> Favorites</Typography>
                            </Box>
                            <Button className="filterBoxDiv" style={{ marginLeft: '7px' }}>
                                <Typography className="filterBoxTextTitle">Media Type</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Button className="filterBoxDiv">
                                <Typography className="filterBoxTextTitle">Date</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Button className="filterBoxDiv">
                                <Typography className="filterBoxTextTitle">Things</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Button className="filterBoxDiv">
                                <Typography className="filterBoxTextTitle">People</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Button className="filterBoxDiv">
                                <Typography className="filterBoxTextTitle">Location</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                            <Button className="filterBoxDiv">
                                <Typography className="filterBoxTextTitle">Permission</Typography>
                                <IconButton><KeyboardArrowDownIcon /></IconButton>
                            </Button>
                        </Box>
                        <Box className="filterBoxDiv">
                            <Typography className="filterBoxTextTitle">Date Taken</Typography>
                            <IconButton style={{ marginLeft: '10px', padding: '0' }}><KeyboardArrowDownIcon /></IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                    <Paper style={{ width: '100%', borderRadius: '40px', border: '1px solid #D6D3D1' }}>
                        <IconButton type="button" style={{ padding: "10px", color: "#A8A29E" }}> <SearchIcon /> </IconButton>
                        <InputBase style={{ marginLeft: 1, color: "#A8A29E", fontSize: '16px', width: '94%', fontWeight: 400 }} placeholder="Search" />
                        <IconButton type="button" style={{ color: "#A8A29E", padding: "10px" }}>
                            <PhotoCameraOutlinedIcon />
                        </IconButton>
                    </Paper>
                </Box>
                <Box>
                    <>
                        <Box style={{ marginTop: '10px' }}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox icon={<div
                                    style={{
                                        width: 20,
                                        border: '1px solid #333',
                                        borderRadius: '4px',
                                        boxSizing: 'border-box',
                                        height: 20,
                                    }} />}
                                    checkedIcon={<div style={{
                                        backgroundColor: '#557C29',
                                        height: 20,
                                        width: 20,
                                        alignItems: 'center',
                                        display: 'flex',
                                        borderRadius: '4px',
                                        justifyContent: 'center',
                                    }} >
                                        <CheckIcon style={{ color: 'white', fontSize: 18 }} />
                                    </div>
                                    } />
                                <Typography className="dateTypoText"> Apr 04, 2024</Typography></Box>
                            <Box style={{ display: 'flex', gap: '5px' }}>
                                <Box className="dummyImgBox">
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                        <Checkbox style={{ color: 'white' }} />
                                        <IconButton><MoreVertIcon style={{ color: 'white' }} /></IconButton>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '90px', padding: '22px', marginRight: '35px' }}>
                                        <Box>
                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                        </Box>
                                        <Box style={{ display: 'flex', marginRight: '5px' }}>
                                            <ChatBubbleOutlineIcon style={{ color: 'white' }} /><Typography style={{ color: 'white', marginLeft: '1px' }}>2</Typography>
                                            <FavoriteIcon style={{ color: 'red', marginLeft: '3px' }} /><Typography style={{ color: 'white' }}>3</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="dummyImgBox">
                                    <Box style={{ display: 'flex', alignItems: 'center', padding: '10px', justifyContent: 'space-between' }}>
                                        <Checkbox style={{ color: 'white' }} />
                                        <IconButton><MoreVertIcon style={{ color: 'white' }} /></IconButton>
                                    </Box>
                                    <Box style={{ display: 'flex', marginTop: '90px', justifyContent: 'space-between', padding: '22px', marginRight: '35px' }}>
                                        <Box>
                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                        </Box>
                                        <Box style={{ display: 'flex' }}>
                                            <ChatBubbleOutlineIcon style={{ color: 'white' }} /><Typography style={{ color: 'white', marginLeft: '1px' }}>2</Typography>
                                            <FavoriteIcon style={{ marginLeft: '3px', color: 'red' }} /><Typography style={{ color: 'white' }}>3</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                </Box>

                <Dialog
                    open={this.state.infoModalOpen}
                    onClose={this.handleCloseModal}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            width: '560px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        }
                    }}
                >
                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: 15, alignItems: 'center' }}>
                        <Typography style={{
                            fontSize: 22,
                            fontWeight: 700,
                            fontFamily: "Inter",
                            color: '#1C2110'
                        }}>Group info</Typography>
                        <CloseIcon style={{ cursor: 'pointer', color: '#334155' }} onClick={this.handleCloseModal} />
                    </Box>
                    <Divider />
                    <DialogBoxContent>
                        <Box>
                            <Typography className="typoTextTitle">
                                Group name
                            </Typography>
                            <div className="textFieldCss">
                                <input className="inputTextFieldCSS"
                                    type="text"
                                    id="add-people-input"
                                    placeholder="Group name"
                                />
                            </div>
                        </Box>
                        <Typography className="typoTextTitle">
                            Share
                        </Typography>
                        <Typography className="typoTextTitle">
                            People, family or friends
                        </Typography>
                        <div className="textFieldCss">
                            <input className="inputTextFieldCSS"
                                type="text"
                                id="add-people-input"
                                placeholder="Add people who you want to share this file"
                            />
                            <div className="infoIconBox">
                                <img src={iIcon} className="infoIcon" />
                                <Button className="ContriBtn">
                                    Contributor
                                    <KeyboardArrowDownIcon className="keyIcon" />
                                </Button>
                            </div>
                        </div>
                        <Box className="shareBox">
                            <Typography className="linkText" >
                                Shareable link
                            </Typography>
                            <div className="contriDivBox">
                                <img src={iIcon} className="infoIcon" />
                                <Button className="ContriBtn">
                                    Contributor
                                    <KeyboardArrowDownIcon className="keyIcon" />
                                </Button>
                            </div>
                        </Box>
                        <Typography className="infoTitleText">Copy this link if you want to share with any user who has the link.</Typography>
                        <div className="textFieldCss">
                            <input className="inputTextFieldCSS"
                                type="text"
                                id="add-people-input"
                                placeholder="Add people who you want to share this file"
                            />
                        </div>
                    </DialogBoxContent>
                    <Divider />
                    <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: 25, marginTop: 15 }}>
                        <Button style={{ width: "141px", height: 56, borderRadius: 8, border: "1px solid #557C29", backgroundColor: '#F8FFE7' }}  >
                            <LinkIcon style={{ color: "#557C29", marginRight: '5px' }} /><Typography style={{ fontWeight: 700, fontSize: 16, color: "#557C29", textTransform: 'none' }}>Copy link</Typography>
                        </Button>
                        <Button style={{ backgroundColor: "#557C29", width: "141px", height: 56, borderRadius: 8, textTransform: 'none' }} data-test-id="handleCloseModal" onClick={this.handleCloseModal}>
                            <Typography style={{ fontWeight: 700, fontSize: 16, fontFamily: "Poppins", color: "#fff" }}>Done</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container >
        );
    }
}

// Customizable Area Start
const DialogBoxContent = styled(Box)({
    padding: '20px',
    "& .typoTextTitle": {
        fontWeight: 700,
        fontSize: 16,
        fontFamily: 'Poppins',
        color: '#1C2110',
        marginBottom: '5px'
    },
    "& .textFieldCss": {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '4px',
        height: '56px',
        padding: '0 10px',
        marginTop: '10px',
        marginBottom: '10px'
    },
    "& .inputTextFieldCSS": {
        maxWidth: '497px',
        width: '100%',
        fontSize: '14px',
        border: 'none',
        outline: 'none',
    },
    "& .groupTitle": {
        fontSize: 22,
        fontWeight: 700,
        fontFamily: "Inter",
        color: '#1C2110'
    },
    "& .infoIconBox": {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        marginLeft: 40
    },
    "& .ContriBtn": {
        color: '#557C29',
        cursor: 'pointer',
        textTransform: 'none',
        fontWeight: 700,
        fontFamily: 'Poppins',
        fontSize: 14
    },
    "& .infoIcon": {
        width: 20,
        height: 20
    },
    "& .infoTitleText": {
        fontSize: 14,
        fontFamily: 'Poppins',
        fontWeight: 400
    },
    "& .linkText": {
        fontWeight: 700,
        fontSize: 16,
        fontFamily: 'Poppins',
        color: '#1C2110'
    },
    "& .keyIcon": {
        marginLeft: '8px'
    },
    "& .shareBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px'
    },
    "& .contriDivBox": {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    }
})


const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    marginLeft: '20px',
    "& .titleTypography": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .cancelBtn": {
        borderRadius: '8px',
        border: '1px solid #C2E176',
        backgroundColor: '#F8FFE7',
        width: '109px',
        height: '44px',
        '& .MuiButton-label': {
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 700,
            color: '#8CA74B',
            fontFamily: "Poppins",
            lineHeight: "24px",
        }
    },
    "& .doneBtn": {
        width: '109px',
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        height: '44px',
        '& .MuiButton-label': {
            fontWeight: 700,
            fontSize: "16px",
            fontFamily: "Poppins",
            textTransform: "none",
            color: 'white',
            lineHeight: "24px",
        }
    },
    "& .selectText": {
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: '#1C2110',
        lineHeight: "28px",
    },
    "& .bgDigitBox": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '28px',
        borderRadius: '4px',
        backgroundColor: '#557C29',
    },
    "& .selectedItemDigit": {
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: '#FFFFFF',
        lineHeight: "28px",
    },
    "& .selectedText": {
        fontWeight: 400,
        fontSize: "20px",
        fontFamily: "Poppins",
        color: '#1C2110',
        lineHeight: "28px",
    },
    "& .dummyImgBox": {
        backgroundImage: `url(${dummyCreateImg})`,
        backgroundRepeat: 'no-repeat',
        width: '17%',
        height: '230px'
    },
    "& .dateTypoText": {
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: "Poppins",
        color: '#1C2110',
        lineHeight: "26px",
    },
    "& .filterBoxDiv": {
        display: 'flex',
        fontSize: "16px",
        alignItems: 'center',
        fontFamily: "Poppins",
        color: '#5D6063',
        textTransform: "none",
        fontWeight: 700,
        lineHeight: '24px',
    },
    "& .filterBoxTextTitle": {
        fontFamily: "Poppins",
        color: '#5D6063',
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "none",
        lineHeight: '24px',
    },
    "& .filterMainBoxs": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px'
    },
    "& .filterTextTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: "#1C2110",
    },
    "& .filterSubBoxs": {
        display: 'flex',
    },
})
// Customizable Area End

