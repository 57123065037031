import React from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    InputBase,
    Paper,
    Popover,
    Typography
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import SharingDashboardController, {
    FilterButtonProps,
    Props,
} from "./SharingDashboardController";
import { avatar, categoryIcon, groupImg, people1, people2, photoExpandImg, tree, videoBtn } from "./assets";
import ToggleButton from '@mui/material/ToggleButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Pagination from '@material-ui/lab/Pagination';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import StarIcon from '@material-ui/icons/Star';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from "@mui/icons-material/Search";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CreateGroup from './CreateGroup.web'

export default class MyFiles extends SharingDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    FilterButton = ({ label }: FilterButtonProps) => (
        <Button className="filterBox">
            <Typography className="filterBoxText">{label}</Typography>
            <IconButton><KeyboardArrowDownIcon /></IconButton>
        </Button>
    );

    SearchBar = () => (
        <Box style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <Paper style={{ width: '100%', borderRadius: '40px', border: '1px solid #D6D3D1' }}>
                <IconButton type="button" style={{ color: "#A8A29E", padding: "10px" }}> <SearchIcon /> </IconButton>
                <InputBase style={{ marginLeft: 1, color: "#A8A29E", fontSize: '16px', fontWeight: 400, width: '94%' }} placeholder="Search" />
                <IconButton type="button" style={{ padding: "10px", color: "#A8A29E" }} aria-label="search">
                    <PhotoCameraOutlinedIcon />
                </IconButton>
            </Paper>
        </Box>
    );

    ToggleButton = ({ value, selectedView, onChange, children }: { value: string; selectedView: string; onChange: (event: React.MouseEvent<HTMLElement>, newValue: string) => void; children: React.ReactNode }) => (
        <ToggleButton
            value={value}
            onChange={onChange}
            style={{
                width: value === 'myFiles' ? '128px' : '185px',
                height: '52px',
                fontWeight: 700,
                fontSize: 16,
                fontFamily: 'Poppins',
                textTransform: 'none',
                borderRadius: 8,
                borderColor: selectedView === value ? '#557C29' : "transparent",
                color: selectedView === value ? '#557C29' : '#A8A29E',
                backgroundColor: selectedView === value ? '#F8FFE7' : '#f7f0f0'
            }}
        >
            {children}
        </ToggleButton>
    );
    // Customizable Area End

    render() {
        return (
            <Container>
                {this.state.showCreateGroupFile ? <CreateGroup navigation={undefined} id={""} /> : (
                    <>
                        <Box className="Content">
                            <Box >
                                <Typography className="sharingTitle">Sharing</Typography>
                            </Box>
                            <Box className="topBtnsBox">
                                <ToggleButtonGroup style={{ height: '60px', gap: '2px' }} exclusive onChange={this.handleToggle}>
                                    <this.ToggleButton value="myFiles" selectedView={this.state.selectedView} onChange={this.handleToggle}>My files</this.ToggleButton>
                                    <this.ToggleButton value="sharedWithMe" selectedView={this.state.selectedView} onChange={this.handleToggle}>Shared with me</this.ToggleButton>
                                </ToggleButtonGroup>
                                <Box className="profileDivBox">
                                    <Box>
                                        <img src={avatar} />
                                        <img src={avatar} style={{ marginLeft: '-15px' }} />
                                        <img src={avatar} style={{ marginLeft: '-15px' }} />
                                        <img src={avatar} style={{ marginLeft: '-15px' }} />
                                    </Box>
                                    <Typography style={{ color: '#8CA74B', fontWeight: 700, fontSize: '16px', marginLeft: '10px', marginRight: '5px' }}> +20 </Typography>
                                    <IconButton
                                        data-test-id="handleDrawerOpen"
                                        aria-label="delete"
                                        size="small"
                                        onClick={this.handleDrawerOpen}
                                    >
                                        <ExpandMoreIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box className="secondDiv">
                                <Button className="createBtn" onClick={this.handleCreateClick}><AddCircleOutlineOutlinedIcon /><Typography className="createBtnText">Create</Typography></Button>
                                <Button className="deleteBtn"><DeleteOutlineOutlinedIcon style={{ color: '#557C29' }} /><Typography className="deleteBtnText">Delete</Typography></Button>
                            </Box>
                            <Box style={{ marginTop: '30px' }}>
                                <Typography className="filterText">Filter by</Typography>
                                <Box className="filterMainBox">
                                    <StarIcon style={{ height: '16px', width: '16px' }} /><this.FilterButton label="Favorites" />
                                    <this.FilterButton label="Media Type" />
                                    <this.FilterButton label="Date" />
                                    <this.FilterButton label="Things" />
                                    <this.FilterButton label="People" />
                                    <this.FilterButton label="Location" />
                                    <this.FilterButton label="Shared with" />
                                    <Divider orientation="vertical" flexItem style={{ height: '40px', marginTop: '10px' }} />
                                    <Box style={{ marginLeft: '15px' }} className="filterBox">
                                        <img src={categoryIcon} />
                                        <IconButton><KeyboardArrowDownIcon /></IconButton>
                                    </Box>
                                </Box>
                            </Box>
                            <this.SearchBar />
                            <Box style={{ marginTop: '40px', marginBottom: '40px' }}>
                                <Button className="expandBoxs" data-test-id="handleGroupExpand" onClick={this.handleGroupExpand}>
                                    <Typography className="expandBtnText">Groups</Typography>
                                    <IconButton>{this.state.isGroupsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                </Button>
                                {this.state.isGroupsExpanded && (
                                    <>
                                        <Box className="groupExpandBox">
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Checkbox style={{ color: "#557C29" }} />
                                                <Box>
                                                    <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                    <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '19px' }}>
                                                <img src={groupImg} style={{ width: '170px' }} />
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: '#1C1917', }}>My Photos</Typography>
                                                <IconButton style={{ backgroundColor: '#8CA74B', borderRadius: '8px', height: '24px', padding: '0' }}><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></IconButton>
                                            </Box>

                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                            <Button style={{ fontFamily: "Poppins", fontWeight: 700, fontSize: "14px", color: '#557C29', textTransform: 'none' }}>View all</Button>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ margin: '0 10px', color: "#557C29" }}>
                                                    {this.state.page} of {this.state.totalPages}
                                                </Typography>
                                                <Box style={{ display: 'flex' }}>
                                                    <Button
                                                        onClick={() => this.handlePageChange(null, this.state.page - 1)}
                                                        disabled={this.state.page === 1} >
                                                        <KeyboardArrowLeftIcon />
                                                    </Button>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Button
                                                        onClick={() => this.handlePageChange(null, this.state.page + 1)}
                                                        disabled={this.state.page === this.state.totalPages} >
                                                        <KeyboardArrowRightIcon />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                <Button className="expandBoxs" data-test-id="handlePhotoExpand" onClick={this.handlePhotoExpand}>
                                    <Typography className="expandBtnText">Photos</Typography>
                                    <IconButton>{this.state.isPhotoExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                </Button>
                                {this.state.isPhotoExpanded && (
                                    <>
                                        <Box style={{ display: 'flex', gap: '10px' }}>
                                            <Box className="photoExpandBox">
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                                    <Checkbox style={{ color: 'white' }} />
                                                    <IconButton><MoreVertIcon style={{ color: 'white' }} /></IconButton>
                                                </Box>
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '90px', padding: '22px' }}>
                                                    <Box>
                                                        <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                        <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                    </Box>
                                                    <Box style={{ display: 'flex', marginRight: '5px' }}>
                                                        <ChatBubbleOutlineIcon style={{ color: 'white' }} /><Typography style={{ color: 'white', marginLeft: '1px' }}>2</Typography>
                                                        <FavoriteIcon style={{ color: 'red', marginLeft: '3px' }} /><Typography style={{ color: 'white' }}>3</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="photoExpandBox">
                                                <Box style={{ display: 'flex', alignItems: 'center', padding: '10px', justifyContent: 'space-between' }}>
                                                    <Checkbox style={{ color: 'white' }} />
                                                    <IconButton><MoreVertIcon style={{ color: 'white' }} /></IconButton>
                                                </Box>
                                                <Box style={{ display: 'flex', marginTop: '90px', justifyContent: 'space-between', padding: '22px' }}>
                                                    <Box>
                                                        <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                        <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                    </Box>
                                                    <Box style={{ display: 'flex' }}>
                                                        <ChatBubbleOutlineIcon style={{ color: 'white' }} /><Typography style={{ color: 'white', marginLeft: '1px' }}>2</Typography>
                                                        <FavoriteIcon style={{ marginLeft: '3px', color: 'red' }} /><Typography style={{ color: 'white' }}>3</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                            <Button style={{ textTransform: 'none', fontFamily: "Poppins", color: '#557C29', fontWeight: 700, fontSize: "14px", }}>View all</Button>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ margin: '0 10px', color: "#557C29" }}>
                                                    {this.state.page} of {this.state.totalPages}
                                                </Typography>
                                                <Box style={{ display: 'flex' }}>
                                                    <Button disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 2)} > <KeyboardArrowLeftIcon /> </Button>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Button disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)} ><KeyboardArrowRightIcon /> </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <Divider style={{ marginBottom: '5px', marginTop: '5px' }} />
                                <Button className="expandBoxs" data-test-id="handlePeopleExpand" onClick={this.handlePeopleExpand}>
                                    <Typography className="expandBtnText">People</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                {this.state.isPeopleExpanded && (
                                    <>
                                        <Box style={{ display: 'flex', gap: '30px', marginBottom: '10px' }}>
                                            <Box >
                                                <img src={people1} />
                                                <Box className="peopleNameBox"> <Checkbox style={{ color: "#557C29" }} /><Typography>Kate</Typography></Box>
                                            </Box>
                                            <Box >
                                                <img src={people2} />
                                                <Box className="peopleNameBox"> <Checkbox style={{ color: "#557C29" }} /><Typography>Sarah</Typography></Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                            <Button style={{ fontFamily: "Poppins", fontSize: "14px", color: '#557C29', fontWeight: 700, textTransform: 'none' }}>View all</Button>
                                            <Box style={{ alignItems: 'center', display: 'flex', }}>
                                                <Typography style={{ margin: '0 10px', color: "#557C29" }}>
                                                    {this.state.page} of {this.state.totalPages}
                                                </Typography>
                                                <Box style={{ display: 'flex' }}>
                                                    <Button onClick={() => this.handlePageChange(null, this.state.page - 1)} disabled={this.state.page === 1} > <KeyboardArrowLeftIcon /> </Button>
                                                    <Divider flexItem orientation="vertical" />
                                                    <Button onClick={() => this.handlePageChange(null, this.state.page + 1)} disabled={this.state.page === this.state.totalPages}> <KeyboardArrowRightIcon /> </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                <Button className="expandBoxs" data-test-id="handleMemoriesExpand" onClick={this.handleMemoriesExpand}>
                                    <Typography className="expandBtnText">Memories</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                {this.state.isMemoriesExpanded && (
                                    <>
                                        <Box style={{ gap: '20px', display: 'flex' }}>
                                            <Box className="momentExpandBox">
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                                    <Checkbox style={{ color: "#557C29" }} />
                                                    <IconButton><MoreVertIcon style={{ color: '#5D6063' }} /></IconButton>
                                                </Box>
                                                <Box style={{ padding: '0px 20px 20px 20px' }}>
                                                    <img src={videoBtn} />
                                                    <Typography className="birthdayText">Birthday</Typography>
                                                    <Typography className="birthdayDesText">Created by me, 10 Mar</Typography>
                                                </Box>
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                                                    <Typography className="birthdayDigitText">20 files</Typography>
                                                    <Box>
                                                        <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                        <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="momentExpandBox">
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                                                    <Checkbox style={{ color: "#557C29" }} />
                                                    <IconButton><MoreVertIcon style={{ color: '#5D6063' }} /></IconButton>
                                                </Box>
                                                <Box style={{ padding: '0px 20px 20px 20px' }}>
                                                    <img src={videoBtn} />
                                                    <Typography className="birthdayText">Anniversary</Typography>
                                                    <Typography className="birthdayDesText">Created by me, 20 Feb</Typography>
                                                </Box>
                                                <Box style={{ display: 'flex', padding: '20px', justifyContent: 'space-between', }}>
                                                    <Typography className="birthdayDigitText">10 files</Typography>
                                                    <Box>
                                                        <img src={avatar} style={{ width: '26px', height: '26px' }} />
                                                        <img src={avatar} style={{ marginLeft: '-15px', width: '26px', height: '26px' }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '20px' }}>
                                            <Button style={{ fontFamily: "Poppins", fontWeight: 700, textTransform: 'none', fontSize: "14px", color: '#557C29', }}>View all</Button>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ margin: '0 10px', color: "#557C29" }}> {this.state.page} of {this.state.totalPages} </Typography>
                                                <Box style={{ display: 'flex' }}>
                                                    <Button disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 1)} > <KeyboardArrowLeftIcon /> </Button>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Button disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)}> <KeyboardArrowRightIcon />   </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                <Button className="expandBoxs" data-test-id="familyTreeExpandBtn" onClick={this.handleFamilyTreeExpand}>
                                    <Typography className="expandBtnText">Family Tree</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                {this.state.isFamilyTreeExpanded && (
                                    <>
                                        <Box style={{ display: 'flex', gap: '20px' }}>
                                            <Box className="momentExpandBox">
                                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                                    <Checkbox style={{ color: "#557C29" }} />
                                                    <IconButton><MoreVertIcon style={{ color: '#5D6063' }} /></IconButton>
                                                </Box>
                                                <Box style={{ padding: '10px' }}>
                                                    <IconButton><img src={tree} /></IconButton>

                                                </Box>
                                                <Box style={{ marginLeft: '18px' }}>
                                                    <Typography className="birthdayText">kate family tree</Typography>
                                                    <Typography className="birthdayDesText">Modified by Kate, 10 Mar</Typography>
                                                </Box>
                                                <Box style={{ display: 'flex', padding: '20px', justifyContent: 'space-between' }}>
                                                    <Typography className="birthdayDigitText">50 members</Typography>
                                                    <Box>
                                                        <img src={avatar} style={{ width: '26px', height: '26px' }} />
                                                        <img src={avatar} style={{ height: '26px', width: '26px', marginLeft: '-15px' }} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                            <Button style={{ fontWeight: 700, fontFamily: "Poppins", color: '#557C29', fontSize: "14px", textTransform: 'none' }}>View all</Button>
                                            <Box style={{ alignItems: 'center', display: 'flex' }}>
                                                <Typography style={{ color: "#557C29", margin: '0 10px' }}> {this.state.page} of {this.state.totalPages} </Typography>
                                                <Box style={{ display: 'flex' }}>
                                                    <Button disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 1)}  >
                                                        <KeyboardArrowLeftIcon />
                                                    </Button>
                                                    <Divider flexItem orientation="vertical" />
                                                    <Button disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)} >
                                                        <KeyboardArrowRightIcon />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
                                <Button className="expandBoxs" data-test-id="expandBoxs" onClick={this.handleRecipesExpand}>
                                    <Typography className="expandBtnText">Recipes</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                {this.state.isRecipesExpanded && (
                                    <>
                                        <Box style={{ display: 'flex', gap: '10px' }}>
                                            <Box style={{ width: '20%' }}>
                                                <Box className="recipesExpandBox">
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Checkbox style={{ color: "white" }} />
                                                        <IconButton><MoreVertIcon style={{ color: 'white' }} /></IconButton>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: '#1C2110', }}>pizza</Typography>
                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ChatBubbleOutlineIcon style={{ color: '#5D6063' }} /><Typography style={{ color: '#5D6063', marginLeft: '1px' }}>2</Typography>
                                                            <FavoriteIcon style={{ color: 'red', marginLeft: '3px' }} /><Typography style={{ color: '#5D6063' }}>3</Typography>
                                                            <IconButton><MoreVertIcon style={{ color: '#5D6063' }} /></IconButton>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                        <Typography style={{ fontFamily: "Poppins", fontWeight: 400, fontSize: "14px", color: '#5D6063', marginLeft: '5px' }}>Me</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
                                            <Button style={{ textTransform: 'none', fontFamily: "Poppins", color: '#557C29', fontWeight: 700, fontSize: "14px", }}>View all</Button>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ margin: '0 10px', color: "#557C29" }}>
                                                    {this.state.page} of {this.state.totalPages}
                                                </Typography>
                                                <Box style={{ display: 'flex' }}>
                                                    <Button data-test-id="handlePageBtn" disabled={this.state.page === 1} onClick={() => this.handlePageChange(null, this.state.page - 2)} > <KeyboardArrowLeftIcon /> </Button>
                                                    <Divider orientation="vertical" flexItem />
                                                    <Button data-test-id="handlePageButton" disabled={this.state.page === this.state.totalPages} onClick={() => this.handlePageChange(null, this.state.page + 1)} ><KeyboardArrowRightIcon /> </Button>
                                                </Box>
                                            </Box>

                                        </Box>

                                    </>
                                )}
                            </Box>
                        </Box>

                        <Popover className="photoModal" open={this.state.drawerOpen} onClose={this.handleDrawerClose} style={{
                            top: 280,
                            left: 1550,
                            position: 'absolute'
                        }} >
                            <PopoverPhotoContent>
                                <Box className="mainDivPop">
                                    <Box style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <Typography style={{
                                            color: '#475569',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                            fontSize: "14px",
                                            fontFamily: 'Poppins',
                                        }} >
                                            Shared with
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box style={{ display: 'flex', alignItems: 'center', gap: '30px', padding: '10px' }}>
                                        <img src={avatar} />
                                        <Typography >
                                            Sarah
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box style={{ alignItems: 'center', gap: '30px', display: 'flex', padding: '10px' }}>
                                        <img src={avatar} />
                                        <Typography >
                                            Kate
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box style={{ display: 'flex', gap: '30px', alignItems: 'center', padding: '10px' }}>
                                        <img src={avatar} />
                                        <Typography >
                                            Emma
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <Pagination count={8} size="small" style={{ color: "#557C29" }} />
                                    </Box>
                                </Box>
                            </PopoverPhotoContent>
                        </Popover>
                    </>
                )}
            </Container >
        );
    }
}

// Styled components
const PopoverPhotoContent = styled(Box)({
    width: '300px',
    "& .mainDivPop": {
        padding: '15px',
        borderRadius: '8px',
        border: '1px solid #E7E5E4'
    }
})

const Container = styled(Box)({
    "& .Content": {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginLeft: '20px'
    },
    "& .sharingTitle": {
        fontFamily: "Poppins",
        color: "#1C2110",
        fontSize: "30px",
        fontWeight: 700,
        letterSpacing: '-0.5%'
    },
    "& .img": {
        height: '239px',
        width: '239px'
    },
    "& .centerDiv": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '50px',
        marginBottom: '50px'
    },
    "& .topBtnsBox": {
        marginTop: '30px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .profileDivBox": {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #5D6063',
        borderRadius: '6px',
        padding: '10px'
    },
    "& .secondDiv": {
        display: 'flex',
        gap: '15px'
    },
    "& .createBtn": {
        backgroundColor: "#557C29",
        height: '44px',
        borderRadius: '8px',
        padding: '4px',
        width: '141px',
        marginTop: '40px',
        color: "#FFFFFF",
    },
    '& .createBtnText': {
        marginLeft: '8px',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        color: "#FFFFFF",
        textTransform: "none",
    },
    "& .deleteBtn": {
        backgroundColor: "#F8FFE7",
        height: '44px',
        borderRadius: '8px',
        padding: '4px',
        width: '141px',
        marginTop: '40px',
        color: "#FFFFFF",
        border: "1px solid #557C29"
    },
    '& .deleteBtnText': {
        marginLeft: '8px',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: '24px',
        color: "#557C29",
        textTransform: "none",
    },
    "& .filterMainBox": {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px'
    },
    "& .filterText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: "#1C2110",
    },
    "& .filterSubBox": {
        display: 'flex',
    },
    "& .filterBox": {
        display: 'flex',
        alignItems: 'center',
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
        textTransform: "none"
    },
    "& .filterBoxText": {
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
        textTransform: "none",
    },
    "& .expandBoxs": {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#1C2110',
        width: '100%',
        padding: '10px'
    },
    "& .expandBtnText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: '#1C2110',
        textTransform: "none"
    },
    "& .groupExpandBox": {
        padding: '20px',
        width: '200px',
        borderRadius: '8px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
    },
    "& .photoExpandBox": {
        backgroundImage: `url(${photoExpandImg})`,
        backgroundRepeat: 'no-repeat',
        width: '17%',
        height: '230px'
    },
    "& .recipesExpandBox": {
        backgroundImage: `url(${photoExpandImg})`,
        backgroundRepeat: 'no-repeat',
        height: '230px',
        borderBottom: '1px solid #5D6063'
    },
    "& .peopleNameBox": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F8FFE7',
        borderRadius: '8px',
        padding: '10px',
        marginTop: '10px'
    },
    "& .momentExpandBox": {
        border: '1px solid #D6D3D1',
        borderRadius: '12px',
        width: '280px'
    },
    "& .birthdayText": {
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
    },
    "& .birthdayDesText": {
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: '22px',
    },
    "& .birthdayDigitText": {
        color: '#A8A29E',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: '18px',
    }
});

